import { v4 as uuidv4 } from 'uuid'
import { groupBy, toArray } from 'lodash'
import baseDataServices from '@/services/baseDataServices'
import salesAssistantService from '@/services/salesAssistant'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters([
      'userInfo'
    ]),
    isDealer() {
      return this.userInfo.type === 1 
    },
  },
  methods: {
    // 组件搜索框数据
    async createClueFilterOptions() {
      let ret = []
      const that = this
      const dictHash = this.$store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        let list = dictHash[dictType] || []
        if (dictType === 2000) {
          // list = list.filter(({ code }) => { return code != 2000000 })
          if (this.leadStageCode === 'OPPORTUNITY') {
            const includeCodes = ['2000000', '2000013', '2000050', '2000052', '2000055', '2000057', '2000060', '2000065', '2000067', '2000040', '2000045']
            list = list.filter(({ code }) => includeCodes.includes(code))
          }
          let listGB = groupBy(list, 'name')
          listGB = toArray(listGB).map(i => {
            if (i.length <= 1) return i[0]

            const name = i[0].name
            return {
              name,
              values: i,
              code: i.map(j => j.code),
              id: i.map(j => j.id).join(',')
            }
          })
          list = listGB
        }
        return list.map(({ code: value, id, name: label }) => ({
          id,
          label,
          value,
        }))
      }
      if (!this.isDealer) {
        const { parentCode='',
          childCode='',
          dealerProvinceCode='',
          dealerCityCode='',
          dealerIds=''
        } = this.$route.query
        const parentList = await salesAssistantService.getRegionTreeApi()
        // 大区
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('大区'),
          field: 'parentCode',
          defaultValue: parentCode,
          options: parentList.map(({ code, name, children=[] }) => ({ id: code, code, name, children })),
        })
        // 城市区域
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('城市区域'),
          multiple: true,
          defaultValue: childCode,
          field: 'childCode',
          options: [],
        })
        const provinceList = await salesAssistantService.getPronceApi({ parentId: 0 })
        // 省份
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('省份'),
          field: 'dealerProvinceCode',
          defaultValue: dealerProvinceCode,
          options: provinceList.map(({ code, name }) => ({ id: code, code, name })),
        })
        // 城市
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('城市'),
          field: 'dealerCityCode',
          defaultValue: dealerCityCode,
          options: [],
        })
        // 销售门店
        const dealerResult = await salesAssistantService.getRegionPageApi({ childCode: [], cityCode: '', pageNum: 1, pageSize: 100000, parentCode: '', provinceCode: '', dealerType: 1 })
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('销售门店'),
          multiple: true,
          field: 'dealerIds',
          defaultValue: dealerIds,
          dealerType: 1,
          options: dealerResult.dataList.map((item) => { return { code: item.id, name: item.name } })
        })
      }
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: 'datetime',
        label: this.$t('跟进截止时间'),
        start: {
          field: 'start',
          // value: this.filterParams.start
        },
        end: {
          field: 'end',
          // value: this.filterParams.end
        },
      })
      // 线索创建时间
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: 'datetime',
        label: this.$t('线索创建时间'),
        start: {
          field: 'createTimeStart',
        },
        end: {
          field: 'createTimeEnd',
        },
      })
      // 线索分配到店时间
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: 'datetime',
        label: this.$t('线索分配到店时间'),
        start: {
          field: 'distributeTimeStart',
        },
        end: {
          field: 'distributeTimeEnd',
        },
      })

      // 最新跟进时间
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: 'datetime',
        label: this.$t('最新跟进时间'),
        start: {
          field: 'filterFollowTimeStart',
        },
        end: {
          field: 'filterFollowTimeEnd',
        },
      })
      // 判定有效/无效时间
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: 'datetime',
        label: this.$t('判定有效/无效时间'),
        start: {
          field: 'validTimeStart',
        },
        end: {
          field: 'validTimeEnd',
        },
      })
      // 线索有效性
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('线索有效性'),
        multiple: true,
        field: 'validStatus',
        hidden: this.leadStageCode !== 'LEAD',
        options: [
          { id: 0, label: this.$t('待定'), value: 0 },
          { id: 1, label: this.$t('有效'), value: 1 },
          { id: 2, label: this.$t('无效'), value: 2 },
        ],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('购车意向等级'),
        multiple: true,
        field: 'leadLevels',
        options: [
          { id: uuidv4(), label: 'H', value: 'H' },
          { id: uuidv4(), label: 'A', value: 'A' },
          { id: uuidv4(), label: 'B', value: 'B' },
          { id: uuidv4(), label: 'C', value: 'C' },
          { id: uuidv4(), label: 'U', value: 'U' },
          { id: uuidv4(), label: '未评级', value: '' },
        ],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('顾问评定等级'),
        multiple: true,
        field: 'leadUserLevels',
        options: [
          { id: uuidv4(), label: 'H', value: 'H' },
          { id: uuidv4(), label: 'A', value: 'A' },
          { id: uuidv4(), label: 'B', value: 'B' },
          { id: uuidv4(), label: 'C', value: 'C' },
          { id: uuidv4(), label: 'U', value: 'U' },
          { id: uuidv4(), label: '未评级', value: '' },
        ],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('试驾意向等级'),
        multiple: true,
        field: 'tryDriveLevels',
        options: [
          ...getOptionsByDictType(1012),
          { id: uuidv4(), label: '未评级', value: '' },
        ],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('线索状态'),
        field: 'states',
        multiple: true,
        options: getOptionsByDictType(2000),
      })
      // 试驾情况
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('试驾情况'),
        multiple: false,
        field: 'testDrivingCount',
        options: [
          { id: uuidv4(), label: this.$t('未试驾'), value: 0 },
          { id: uuidv4(), label: this.$t('试驾1次'), value: 1 },
          { id: uuidv4(), label: this.$t('试驾多次'), value: 2 },
        ],
      })
      // 最新一次试驾时间
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: 'datetime',
        label: this.$t('最新一次试驾时间'),
        start: {
          field: 'drivingTimeStart',
        },
        end: {
          field: 'drivingTimeEnd',
        },
      })
      // 最新一次到店时间
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: 'datetime',
        label: this.$t('最新一次到店时间'),
        start: {
          field: 'storeOfTimeStart',
        },
        end: {
          field: 'storeOfTimeEnd',
        },
      })
      // 车系车型，新版
      ret.push({
        id: uuidv4(),
        type: 'cars',
        label: this.$t('意向车型'),
        field: 'cars',
        tagsInput: true,
        fields: ['seriesCodes', 'modelCodes'],
      })
      // 线索归属
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('线索归属'),
        field: 'sourceType',
        multiple: true,
        options: getOptionsByDictType(1007),
      })
      // 来源渠道，新版
      ret.push({
        id: uuidv4(),
        type: 'channels',
        label: this.$t('来源渠道'),
        field: 'channels',
        tagsInput: true,
        fields: ['channelOneId', 'channelTwoId', 'channelThreeId', 'channelFourId', 'channelFiveId'],
        defaultValue: [],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('是否加微'),
        field: 'isWechat',
        options: [
          { id: uuidv4(), label: this.$t('已加微'), value: 1 },
          { id: uuidv4(), label: this.$t('未添加'), value: 0 },
        ],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('是否即将回收'),
        multiple: false,
        field: 'isFutureRecycle',
        get defaultValue() {
          return this.options.find(({ value }) => value === that.filterParams.isFutureRecycle)
        },
        options: [
          { id: uuidv4(), label: this.$t('否'), value: 0 },
          { id: uuidv4(), label: this.$t('是'), value: 1 },
        ],
      })
      // 客户省份城市
      ret.push({
        id: uuidv4(),
        type: 'address',
        label: this.$t('客户省份城市'),
        isShowLabel: false,
        field: 'dealerAddress',
        isShowDistrict: true, // 只展示省市，不展示区
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('购车类型'),
        multiple: true,
        field: 'purchaseTypes',
        options: [
          { id: uuidv4(), label: this.$t('新购'), value: 100970 },
          { id: uuidv4(), label: this.$t('增购'), value: 100980 },
          { id: uuidv4(), label: this.$t('换购'), value: 100990 },
        ],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('置换需求'),
        field: 'isReplace',
        options: [
          { id: uuidv4(), label: this.$t('置换'), value: 1 },
          { id: uuidv4(), label: this.$t('非置换'), value: 0 },
        ],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('是否战败过'),
        field: 'isDefeat',
        options: [
          { id: uuidv4(), label: this.$t('是'), value: 1 },
          { id: uuidv4(), label: this.$t('否'), value: 0 },
        ],
      })
      // 最新一次战败时间
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: 'datetime',
        label: this.$t('最新一次战败时间'),
        start: {
          field: 'defeatTimeStart',
        },
        end: {
          field: 'defeatTimeEnd',
        },
      })
      const failCodesList = await baseDataServices.defeatClueTreeNew({ bizType: this.isDealer ? 1 : '' })
      ret.push({
        id: uuidv4(),
        type: 'commonCascader',
        label: this.$t('最新一次战败原因'),
        field: 'defeatFailCodes',
        tagsInput: true,
        valueType: 'checkedValues',
        options: failCodesList.map(item => {
          return {
            id: uuidv4(),
            name: item.reason,
            value: item.id,
            children: item.children && item.children.length ? item.children.map(item => {
              return {
                id: uuidv4(),
                name: item.reason,
                value: item.id,
                children: []
              }
            }): []
          }
        }),
        // fields: ['failCode', 'failCodeChildren'],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('是否长期战败'),
        field: 'defeatTimes',
        options: [
          { id: uuidv4(), label: this.$t('是'), value: 2 },
          { id: uuidv4(), label: this.$t('否'), value: 0 },
        ],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('客户不愿被联系'),
        field: 'customerNoChat',
        options: [
          { id: uuidv4(), label: this.$t('是'), value: 1 },
          { id: uuidv4(), label: this.$t('否'), value: 0 },
        ],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('是否保存心愿单'),
        field: 'isSaveWishOrder',
        options: [
          { id: uuidv4(), label: this.$t('是'), value: 1 },
          { id: uuidv4(), label: this.$t('否'), value: 0 },
        ],
      })
      if (this.$store.getters.userInfo.type == 1) {
        const dccStaffList = await baseDataServices.getStaffList({ roleLogo: '1014001,1014002', dealerId: this.userInfo.dealers[0]?.id })
        ret.push({
          id: uuidv4(),
          type: 'options',
          label: this.$t('顾问'),
          field: 'followUserIds',
          multiple: true,
          options: dccStaffList.map((item) => ({ ...item, label: item.name, value: item.id })),
        })
      }
      this.filterOptions = ret
    },
  }
}
